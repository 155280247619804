<template>
  <div>
    <v-alert tile dense color="primary700 primary100--text mb-5">
      <div class="d-flex align-center my-1">
        <fai :icon="['fad', 'siren-on']" class="text-h5" />
        <p class="mb-0 mx-4 text-caption lh-1-4">
          Don't touch this shit if you don't know exactly what you're doing. A small fuck up here
          can cost the website thousands very fast.
        </p>
      </div>
    </v-alert>

    <div class="heading d-flex align-center mb-3">
      <h3 class="title">
        Conga
      </h3>
      <v-spacer />
      <v-btn
        small color="blue700" tile
        :ripple="false" :loading="loading.get"
        @click="getFullConga"
      >
        <fai class="blue500--text mr-2" :icon="['fas','sync']" />
        Reset
      </v-btn>
    </div>

    <div ref="editor" class="editor grey100" />

    <v-alert
      dense tile
      class="text-body-2 white--text mb-6"
      :class="draftError ? 'primary700' : 'green800'"
    >
      <div class="d-flex align-center">
        <fai :icon="['fad', draftError ? 'angry' : 'robot']" class="text-h6 mr-3" fixed-width />
        <span class="mt-1">{{ draftError ? draftError : 'Looks good so far' }}</span>
      </div>
    </v-alert>

    <div v-if="diff">
      <h2 class="text-subtitle-1 text-uppercase primary500--text mb-2 font-weight-bold">
        Changes
      </h2>

      <v-divider class="mb-4" />

      <div v-for="(edit, i) in diff" :key="i" class="mb-2">
        <h4 class="text-body-1 mb-2 d-inline-flex align-center mr-2">
          <span class="grey800 px-2 py-1">
            <fai
              :icon="edit.icon"
              class="text-caption"
              :class="edit.classes"
              fixed-width
            />
          </span>
          <span class="grey600 grey100--text py-1 px-2">
            <span v-if="edit.path">{{ edit.path.join('.') }}</span>
            <span v-show="edit.index !== undefined" class="yellow--text">[{{ edit.index }}]</span>
          </span>
        </h4>
        <span v-if="edit.kind === 'A'">
          <span class="grey200--text">{{ edit.item.lhs || '(falsy)' }}</span>
          <fai class="mx-2 blue500--text" :icon="['fad', 'long-arrow-right']" fixed-width />
          <span class="grey100--text">{{ edit.item.rhs || '(falsy)' }}</span>
        </span>
        <span v-else-if="edit.kind !== 'D'">
          <span class="grey200--text">{{ edit.lhs || '(falsy)' }}</span>
          <fai class="mx-2 blue500--text" :icon="['fad', 'long-arrow-right']" fixed-width />
          <span class="grey100--text">{{ edit.rhs || '(falsy)' }}</span>
        </span>
        <span v-else class="grey200--text">removed</span>
      </div>

      <v-divider />

      <div class="mt-4 mb-4">
        <v-checkbox v-model="reviewed" hide-details label="I reviewed my changes and I'm sure of them." />
        <v-checkbox v-model="reviewed2" class="mb-4" hide-details label="I swear to god you fucking cunt I did it." />

        <v-btn
          large color="green800 green500--text" tile
          :ripple="false"
          :disabled="!reviewed || !reviewed2 || !!draftError"
          :loading="loading.update"
          @click="onUpdateConga"
        >
          <fai class="mr-3" :icon="['fas','check']" />
          Update Config
        </v-btn>
      </div>
    </div>

    <v-divider class="my-6" />

    <h4 class="text-body-1 primary500--text font-weight-bold mb-4">
      General settings
    </h4>

    <v-row>
      <v-col cols="12" md="6">
        <v-card color="grey700" class="px-4 py-3">
          <h4 class="text-body-2 primary500--text font-weight-bold">
            Currencies
          </h4>
          <p class="text-caption">
            Changing these can brick the whole site, so don't :)
          </p>

          <v-expansion-panels class="mb-4">
            <v-expansion-panel v-for="(currency, i) in currencies" :key="i">
              <v-expansion-panel-header color="grey600">
                <span v-if="currency.name">
                  <span class="font-weight-semibold  mr-1" v-text="currency.name" />
                  <span class="text-uppercase primary400--text">({{ currency.code }})</span>
                </span>
                <span v-else class="text--disabled">Untitled</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <v-form class="mb-4" @submit.prevent>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <fieldset>
                        <label class="text-body-2 d-block mb-1">Name</label>
                        <v-text-field
                          v-model="currency.name" filled dense color="primary500"
                          placeholder="Euro"
                        />
                      </fieldset>
                    </v-col>

                    <v-col cols="12" md="6">
                      <fieldset>
                        <label class="text-body-2 d-block mb-1">Code</label>
                        <v-text-field
                          v-model="currency.code" filled dense color="primary500"
                          placeholder="eur" max="3" min="3"
                        />
                      </fieldset>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="text-right">
                  <v-btn
                    color="red" class="text-none" small
                    :disabled="loading.currencies"
                    @click="currencies.splice(i, 1)"
                  >
                    <fai :icon="['fad', 'trash']" class="mr-2" />
                    Delete
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="text-right">
            <v-btn
              color="grey100" outlined class="text-none mr-2"
              small :disabled="loading.currencies" @click="onAddNewCurrency"
            >
              <fai :icon="['fas', 'plus']" class="mr-2" />
              Add new
            </v-btn>

            <v-btn
              color="green" class="text-none"
              small :loading="loading.currencies" @click="onSetCurrencies"
            >
              Save
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="grey700" class="px-4 py-3">
          <h4 class="text-body-2 primary500--text font-weight-bold">
            Markets
          </h4>
          <p class="text-caption">
            Changing these can brick the whole site, so don't :)
          </p>

          <v-expansion-panels class="mb-4">
            <v-expansion-panel v-for="(market, i) in markets" :key="i">
              <v-expansion-panel-header color="grey600">
                <span v-if="market.name">
                  <span class="font-weight-semibold  mr-1" v-text="market.name" />
                  <span class="text-uppercase primary400--text">({{ market.slug }})</span>
                </span>
                <span v-else class="text--disabled">Untitled</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="grey600">
                <v-form class="mb-4" @submit.prevent>
                  <v-row dense>
                    <v-col cols="12">
                      <fieldset>
                        <label class="text-body-2 d-block mb-1">Name</label>
                        <v-text-field
                          v-model="market.name" filled dense color="primary500"
                          placeholder="North America"
                        />
                      </fieldset>
                    </v-col>

                    <v-col cols="12" md="6">
                      <fieldset>
                        <label class="text-body-2 d-block mb-1">Slug</label>
                        <v-text-field
                          v-model="market.slug" filled dense color="primary500"
                          placeholder="na"
                        />
                      </fieldset>
                    </v-col>

                    <v-col cols="12" md="6">
                      <fieldset>
                        <label class="text-body-2 d-block mb-1">Currency</label>
                        <v-autocomplete
                          v-model="market.currency"
                          :items="currencies"
                          item-text="code"
                          item-value="id"
                          filled clearable dense
                          placeholder="USD"
                        />
                      </fieldset>
                    </v-col>
                  </v-row>

                  <div class="text-right">
                    <v-btn
                      color="red" class="text-none" small
                      :disabled="loading.markets"
                      @click="markets.splice(i, 1)"
                    >
                      <fai :icon="['fad', 'trash']" class="mr-2" />
                      Delete
                    </v-btn>
                  </div>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="text-right">
            <v-btn
              color="grey100" outlined class="text-none mr-2"
              small :disabled="loading.markets" @click="onAddMarket"
            >
              <fai :icon="['fas', 'plus']" class="mr-2" />
              Add new
            </v-btn>

            <v-btn
              color="green" class="text-none"
              small :loading="loading.markets" @click="onSetMarkets"
            >
              Save
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import diff from 'deep-diff'
import { mapGetters } from 'vuex'
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'

const deepclone = require('rfdc')()

const DIFF_ICONS = {
  N: 'plus',
  D: 'minus',
  E: 'pencil',
  A: 'layer-group',
}

const DIFF_CLASSES = {
  N: 'green500--text',
  D: 'primary500--text',
  E: 'blue500--text',
  A: 'yellow--text',
}

const DEFAULT_CURRENCY_FORM = () => ({
  name: '',
  code: '',
})

const DEFAULT_MARKET_FORM = () => ({
  name: '',
  slug: '',
  currency: '',
})

export default {
  data() {
    return {
      loading: {
        get: false,
        update: false,
        currencies: false,
        markets: false,
      },
      editor: null,
      conga: null,
      draft: null,
      reviewed: false,
      reviewed2: false,
      draftError: null,
      //
      currencies: [],
      markets: [],
    }
  },
  computed: {
    ...mapGetters({
      userConga: 'conga/conga',
    }),
    diff() {
      const diffs = diff(this.conga, this.draft)

      return diffs?.map(i => ({
        ...i,
        icon: ['fas', DIFF_ICONS[i.kind]],
        classes: DIFF_CLASSES[i.kind],
      }))
    },
  },
  watch: {
    userConga: {
      handler(val) {
        if (this.diff && !this.loading.update) {
          this.$toast.warning('Conga changed while you were editing. Resetting...')
        }

        this.getFullConga()
      },
      deep: true,
    },
  },
  mounted() {
    this.editor = new JSONEditor(this.$refs.editor, {
      mode: 'form',
      modes: ['form', 'tree', 'code'],
      enableTransform: false,
      enableSort: false,
      onChange: this.onInput,
    })

    this.getFullConga()
    this.onGetMarkets()
    this.onGetCurrencies()
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    async getFullConga() {
      this.loading.get = true

      try {
        const conga = await this.$socket.request('admin.conga.get')
        this.conga = deepclone(conga)

        this.reset(conga)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.get = false
      }
    },
    async onUpdateConga() {
      if (this.draftError) return
      if (!this.reviewed || !this.reviewed2) return

      this.loading.update = true

      try {
        await this.$socket.request('admin.conga.update', this.draft)
        this.reset()

        this.$toast.success('Conga updated')
      } catch (error) {
        if (Array.isArray(error)) this.$toast.error(error.join(', '))
        else this.$toast.error(error.message)
      } finally {
        this.loading.update = false
      }
    },
    onInput() {
      try {
        const validated = this.editor.get()
        this.draftError = false
        this.draft = validated
      } catch (e) {
        this.draftError = e.message
      }
    },
    reset(conga = this.conga) {
      this.reviewed = false
      this.reviewed2 = false
      this.draftError = false
      this.draft = deepclone(conga)
      this.editor.set(this.draft)
    },
    //
    async onGetMarkets() {
      try {
        this.markets = await this.$socket.request('admin.markets.get')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async onGetCurrencies() {
      try {
        this.currencies = await this.$socket.request('admin.currencies.get')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async onSetMarkets() {
      try {
        await this.$socket.request('admin.markets.set', this.markets.map(i => ({
          slug: i.slug.toLowerCase(),
          name: i.name,
          currency: i.currency.toLowerCase(),
        })))
        this.$toast.success('Markets saved')
        this.onGetMarkets()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async onSetCurrencies() {
      try {
        await this.$socket.request('admin.currencies.set', this.currencies.map(i => ({
          code: i.code.toLowerCase(),
          name: i.name,
        })))
        this.$toast.success('Currencies saved')
        this.onGetCurrencies()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onAddNewCurrency() {
      this.currencies.push(DEFAULT_CURRENCY_FORM())
    },
    onAddMarket() {
      this.markets.push(DEFAULT_MARKET_FORM())
    },
  },
}
</script>

<style lang="scss" scoped>
.editor {
  height: 380px;
}
</style>
